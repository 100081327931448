import { createRouter, createWebHistory } from '@ionic/vue-router'
import { useAuthUserStore } from '../stores/auth-store'
import adminRoutes from './admin-routes'
import mainRoutes from './main-routes'

const routes = [...mainRoutes, ...adminRoutes]

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthUserStore()

  // Wait until Firebase resolves authentication state
  if (!authStore.isAuthReady && to.meta.requiresAuth) {
    await authStore.initAuth()
  }

  const isAuthenticated = authStore.isAuthenticated
  const isOnboarded = authStore.authUser?.onboarded

  // Prevent authenticated and onboarded users from accessing the onboarding page
  if (to.name === 'onboarding' && isAuthenticated && isOnboarded) {
    return next({ name: 'fitness-activity' })
  }

  // Handle routes that require authentication
  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      return next({ name: 'login' })
    }
    if (!isOnboarded && to.name !== 'onboarding') {
      return next({ name: 'onboarding' })
    }
  }
  // Handle routes that require user to be unauthenticated
  else if (to.meta.requiresUnauth && isAuthenticated) {
    return next({ name: 'fitness-activity' })
  }

  next()
})

export default router
