export default [
  {
    path: '/',
    redirect: '/fitness-activity',
  },
  {
    path: '/',
    component: () => import('@/views/MainView.vue'),
    children: [
      {
        path: '',
        redirect: '/fitness-activity',
      },
      // Tab1
      {
        path: 'fitness-activity',
        name: 'fitnessActivityOverview',
        component: () => import('@/views/FitnessActivityView.vue'),
        meta: { requiresAuth: true },
      },
      //Tab2
      {
        path: 'leaderboard',
        name: 'leaderboard',
        component: () => import('@/views/LeaderboardView.vue'),
        meta: { requiresAuth: true },
      },
      //Tab 3
      {
        path: 'member-activities',
        name: 'memberActivities',
        component: () => import('@/views/MemberActivitiesView.vue'),
        meta: { requiresAuth: true },
      },
      //Tab 4
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/ProfileView.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/account',
    name: 'accountView',
    component: () => import('@/views/AccountView.vue'),
  },
  {
    path: '/fitness-activity/:id',
    name: 'fitnessActivityDetails',
    component: () => import('@/components/fitness-activity/pages/FitnessActivityDetails.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/fitness-activity/category/:type',
    name: 'fitnessActivityCategoryDetails',
    component: () => import('@/components/fitness-activity/pages/FitnessActivityCategoryDetails.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/member-activities/:id/results',
    name: 'fitnessActivityResults',
    component: () => import('@/components/fitness-activity/pages/FitnessActivityResults.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/attempt/:id',
    name: 'attempt',
    component: () => import('@/components/attempts/Attempt.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/member-activities/:id/progress',
    name: 'attemptsProgress',
    component: () => import('@/components/attempts/AttemptProgress.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile/:id/edit',
    name: 'profileEdit',
    component: () => import('@/pages/profile/ProfileEditPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('@/pages/user/UserPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/exercise/:id',
    name: 'exercise',
    component: () => import('@/pages/ExercisePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/pages/OnboardingPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/start',
    name: 'start',
    component: () => import('@/views/LandingPageView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicyView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: () => import('@/views/TermsAndConditionsView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import('@/views/ContactUsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/verify-mobile-number',
    name: 'verifyMobileNumber',
    component: () => import('@/views/PINVerificationView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@/views/ForgotPasswordView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: { requiresUnauth: true },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/SignupView.vue'),
    meta: { requiresUnauth: true },
  },
  // 404 route
  {
    path: '/:catchAll(.*)',
    component: () => import('@/components/base/404.vue'),
  },
]
